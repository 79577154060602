import React,{useState, useContext, useEffect} from 'react';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Table } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import { Link } from "react-router-dom";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faEye, faHome, faSearch, faEllipsisH, faEdit } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from "react-to-print";
import { useSnackbar } from 'notistack';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import logo from '../../assets/images/logo/COLLEGELOGO.jpeg';
//import MarkSheet from '../course/MarkSheet';
import { courseDetailsList } from '../../ProData';
import Preloader from '../../components/Preloader/Preloader';
//import { parse } from '@fortawesome/fontawesome-svg-core';

const config = require('../../config.json');

export default function MarkSheetDisplay() {
    const { loggedUser, generateDate, validateLogin, handleImageError } = useContext(ProductContext);
    const [searchTopic, setSearchTopic] = useState('Batch');
    const [searchValue, setSearchValue] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [loaded, setLoaded] = useState(true);
    const [dispReady, setDispReady] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const componentRef = React.useRef(null);
    const [creditsEval, setCreditsEval]=useState(0);
    const [totalCredits, setTotalCredits] = useState(0);
    const [totalPoints, setTotalPoints]= useState(0);
    const [compER, setCompER] = useState("-");
    const [finalGrade, setFinalGrade] = useState("-");
    const [finalQual, setFinalQual] = useState("-");
    const [avgMark, setAvgMark] = useState("-");
    const [courseDetails, setCourseDetails]=useState({courseCode:'', courseName:''});
    const [profileDetails, setProfileDetails]=useState({applicantName:'', fatherName:'', dateOfBirth:'', gender:'Male', nationality:'',
    religiousStatus:'Scholastic', parishDiocese:'', congregationProvince:'', areaCode:'+91',mobileNumber:'', emailID:'', placeOfBirth:'', 
    birthDistrict:'', birthState:'', localAddress:'', localState:'', localPincode:'', localContact:'', perAddress:'', perState:'', perPincode:'',
    perContactNo:'', courseCategory:'Bachelor of Theology', degreeDetails:[], languageDetails:[], courseDetails:[]});

    useEffect(()=>{
        if(loggedUser.isAuthenticated){
        }else{
        validateLogin();
        }
      },[loggedUser])

      const handleSearch=async()=>{
        //event.preventDefault();
        try{
            setLoaded(false);
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            //const searchOption=searchTopic;
            //const searchValue=searchValue;
            //console.log("searchvlaue : ", searchValue);
          await axios.get(`${config.api.invokeURL}/dashboard/student/marksheet/view?searchValue=${searchValue}`, {
            headers: {
              Authorization: idToken,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
           //console.log("response : ", response.data);
            if(response.data.length>0)
            {
                setSearchResults(response.data[1]);
                setProfileDetails(response.data[0][0]);
                processMarks(response.data[1]);
                setDispReady(true);
            }
            enqueueSnackbar(response.data.length + " - student's records found!",{variant:'success'}); 
            setLoaded(true);
        }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        setLoaded(true);
      })
        }catch(error){
          enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        setLoaded(true);
        }
      }

      const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
      }, [componentRef.current]);
    
      const reactToPrintTrigger = React.useCallback(() => {
        return <Button variant="outline-primary"  size="sm">Print</Button>;
      }, []);

      const processMarks=(item)=>{
        const tempCourseDetailsList=courseDetailsList;
        //console.log("coursedetails :", tempCourseDetailsList);
        var tempTotalPoints=0;
        var tempMark=0;
        var tempTotalCredits=0;
        var tempCreditEval=0;
        for(const j in tempCourseDetailsList){
            tempTotalCredits=tempTotalCredits+tempCourseDetailsList[j].courseCredit;
            for(const i in item){
            if(tempCourseDetailsList[j].courseName===item[i].courseName){
               // console.log("credt : ", i, j);
                //console.log(item[i].courseName);
                tempCourseDetailsList[i].courseMarks=parseInt(item[i].courseMarks);
                tempCourseDetailsList[i].coursePoints=item[i].coursePoints;
                tempTotalPoints=tempTotalPoints+item[i].coursePoints;
                tempMark=tempMark+parseInt(item[i].courseMarks);
                tempCreditEval=tempCreditEval+item[i].courseCredit;
                //console.log(i, tempCourseDetailsList[j]);
            }
            }
            //console.log("total points :", creditsEval);
        }
        //const testAvgMark=parseFloat(totalPoints/creditsEval/100*100).toFixed(2);
        const testAvgMark=parseFloat(parseFloat(tempTotalPoints)/parseFloat(tempCreditEval)/100*100).toFixed(2);
        const tempComp=searchResults.filter((item)=>item.courseName==="COMPREHENSIVE EXAM MARKS");
        var tempCompEM="-";
        var tempFinalQual=0;
        if(tempComp.length>0){
          tempCompEM=tempComp.courseMarks;
          tempFinalQual=(parseFloat(testAvgMark)+parseFloat(tempComp.courseMarks))/2;
          setFinalQual(tempFinalQual);
        }else{
          tempCompEM="-";
          setFinalQual("-");
        }
        var tempGrade="-";
        if(tempFinalQual<40){
          tempGrade="-";
        }else if(tempFinalQual>=40 && tempFinalQual<50){
          tempGrade="THIRD CLASS";
        }else if(tempFinalQual>=50 && tempFinalQual<60){
          tempGrade="SECOND CLASS";
        }else if(tempFinalQual>=60 && tempFinalQual<70){
          tempGrade="FIRST CLASS";
        }else if(tempFinalQual>=70 && tempFinalQual<101){
          tempGrade="DISTINCTION";
        }
        setCompER(tempCompEM);
        setFinalGrade(tempGrade);
        //console.log("Test : ", test);
        setAvgMark(testAvgMark);
        setTotalCredits(tempTotalCredits);
        setTotalPoints(tempTotalPoints);
        setCreditsEval(tempCreditEval);
      } 

  return (
    <div>
    <Preloader show={loaded ? false : true} />
    <Breadcrumb>
          <Breadcrumb.Item>
          <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link>
                </Breadcrumb.Item>
          <Breadcrumb.Item>Students</Breadcrumb.Item>
          <Breadcrumb.Item active>Mark Sheet</Breadcrumb.Item>
    </Breadcrumb>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div className="d-block mb-4 mb-md-0">

        <h4>Student's - Mark Sheet - Print View</h4>
      </div>
      <div className="btn-toolbar mb-2 mb-md-0"> 
        <ButtonGroup>
        <ReactToPrint
      content={reactToPrintContent}
      documentTitle="student"
      trigger={reactToPrintTrigger}
    />
        {/*<Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
        <CSVLink
            headers={downloadHeaders}
            filename="download.csv"
            data={searchResults}
            ref={csvLinkEl}
  />*/}
        </ButtonGroup>
      </div>
    </div>
    <div className="table-settings mb-4">
      <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
          <Form.Group id="searchstud">               
            <Form.Select defaultValue="Batch" value={searchTopic} 
            size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
            onChange={(event)=>{setSearchTopic(event.target.value)}}>
            <option value="RegistrationID">Registration No</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col  className="mb-3" md={6}>
          <InputGroup>
            {/*<InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>*/}
          <Form.Control type="text" placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>
          </InputGroup>
        </Col>
        <Col  className="mb-3" md={2}>
            <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
          </Col>
      </Row>
    </div>
    {/*marksheet view*/}
    {dispReady && <div ref={componentRef} className='borderSolid bgColor'>
    <div className="demo-wrap">
  <img className="demo-bg" src={logo} alt="" />
    <div style={{textAlign:"center"}}>
    <img src={logo} width="150" className="shadow-light rounded-circle"/>
    <h4>Vidyajyoti - Institute of Religious Studies - College of Theology</h4>
    <h5>Statement of Marks</h5>
    </div>
   {/*} <div style={{textAlign:"center", padding:"20px"}}>
        <Row >
            <Col className='colorname'>
            <p>Name of the Student</p>
            <h5>{profileDetails.applicantName}</h5>
            </Col>
            <Col className='colorname'>
            <p>Registration No</p>
            <h5>{profileDetails.registrationNo}</h5>
            </Col>
        </Row>
        <Row >
            <Col className='colorname'>
            <p>Name of the Degree</p>
            <h5>{profileDetails.courseCategory}</h5>
            </Col>  
            <Col className='colorname'>
            <p>Degree Code</p>
            <h5>{profileDetails.courseDetails.courseCode}</h5>
            </Col>
        </Row>
          </div>*/}
    <Table style={{textAlign:"center", background:"#fbede6"}}>
      <tbody className='colorname'>
        <tr className='colorname'>
          <th className='colorname' style={{width:"50%"}}>
            <p>Name of the Student</p>
            <h5>{profileDetails.applicantName}</h5>
          </th>
          <th className='colorname'>
            <p>Registration No</p>
            <h5>{profileDetails.registrationNo}</h5>
          </th>
          <th rowSpan={2} className='colorname'>
          <img id="profile" src={`${config.s3.profileUrl}${profileDetails.applicationNo}${"/"}${profileDetails.avatar}${"?t="} ${new Date().getTime()}`} 
          onError={handleImageError}
          alt="Profile" style={{width:"8rem", height:"12rem"}} />
          </th>
        </tr>
        <tr>
        <th className='colorname'>
          <p>Name of the Degree</p>
          <h5>{profileDetails.courseCategory}</h5>
        </th>
        <th className='colorname'>
          <p>Degree Code</p>
          <h5>{profileDetails.courseDetails.courseCode}</h5>
        </th>
        </tr>
      
      </tbody>
    </Table>
    <Table responsive style={{background:"#fbede6"}}>
        <thead >
            <tr className='colorname'>
                <th className='colorname text-center'>#</th>
                <th className='colorname text-center'>Code</th>
                <th className='colorname text-center'>Name</th>
                <th className='colorname text-center'>Credit</th>
                <th className='colorname text-center'>Marks</th>
                <th className='colorname text-center'>Points</th>
            </tr>
        </thead>
        <tbody >
        {searchResults.map((item, index)=>
        <tr key={index} className='colorname'>
        <th className='colorname text-center' scope="row">{index+1}</th>
        <th className='colorname text-center'>{item.courseCode}</th>
        <th className='colorname'>{item.courseName}</th>
        <th className='colorname text-center'>{item.courseCredit}</th>
        <th className='colorname text-center'>{item.courseMarks}</th>
        <th className='colorname text-center'>{item.coursePoints}</th>
        </tr>)}
        </tbody>
    </Table>
    <Table style={{textAlign:"center", background:"#fbede6"}}>
      <tbody className='colorname'>
        <tr>
          <th>
          INTEGRAL ASSESSMENT
          </th>
        </tr>
        <tr className='colorname'>
        <th className='colorname'>
          <p>Total Credits</p>
          <h5>{totalCredits}</h5>
        </th>
        <th className='colorname'>
          <p>Total Points</p>
          <h5>{totalPoints}</h5>
        </th>
        <th className='colorname'>
          <p>Credits Evaluated</p>
          <h5>{creditsEval}</h5>
        </th>
        <th className='colorname'>
          <p>Average Mark</p>
          <h5>{avgMark}%</h5>
        </th>
      </tr>
      <tr  className='colorname'>
        <th  className='colorname' colspan="3">
        COMPREHENSIVE EXAM MARKS
        </th>
        <th  className='colorname'>
          {compER}
        </th>
      </tr>
      <tr  className='colorname'>
        <th  className='colorname' colspan="3">
        FINAL QUALIFICATION
        </th>
        <th  className='colorname'>
          {finalQual}
        </th>
      </tr>
      <tr  className='colorname'>
        <th  className='colorname' colspan="2">
        DEGREE CONFERRED     
        <h5>{profileDetails.courseDetails.courseCode}</h5>
      </th>
        <th  className='colorname' colspan="2">
          GRADE
          <h5>{finalGrade}</h5>
        </th>
      </tr>
      </tbody>
    </Table>
  
    </div>
    </div>}
    </div>
  )
}
