import React, { useState, useContext, useEffect } from 'react';
//import axios from "axios";
//import { Auth } from "aws-amplify";
import { ProductContext } from '../../ProductProvider';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {  faCamera } from "@fortawesome/free-solid-svg-icons";
import Resizer from "react-image-file-resizer";

const config = require('../../config.json');


export default function ProfileImage(props) {
    const { setNewMemberImage } = useContext(ProductContext)
    const inputRef = React.useRef();
    const triggerFileSelectPopup = () => inputRef.current.click();
    const { profileImage, setImageFile, formType}=props;
    const [image, setImage]=useState(`${profileImage}`)
    const [loaded, setLoaded]=useState(false);
    const [viewOnly, setViewOnly] = useState(true);


    useEffect(() => {
      if(formType==="Edit"||formType==="New"){
        setViewOnly(false);
      }else{
        setViewOnly(true);
      }
    }, [profileImage])
    

const handleFileSelection = async(event)=>{
  event.preventDefault();
  if (event.target.files && event.target.files.length > 0) {
    try {
      await Resizer.imageFileResizer(
        event.target.files[0],
        1500,
        1500,
        "JPEG",
        100,
        0,
        (uri) => {
          //console.log(uri);
          setImageFile(uri);
          //console.log("compressed");
         // this.setState({ newImage: uri });
        },
        "blob",
        400,
        400
      );
    } catch (err) {
      console.log(err);
    }
    //setImageFile(event.target.files[0]);
    var tprofile = document.getElementById("profile");
    tprofile.src=await URL.createObjectURL(event.target.files[0]);
    console.log("Event :", tprofile.src)
}
}

 const handleImageError=(e)=>{
  e.target.src = "https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg"
 }

    return (
      <div style={{textAlign:"center"}}> 
      
       {profileImage && <img id="profile" src={`${image}${"?t="} ${new Date().getTime()}`} 
            onError={handleImageError}
            alt="Profile" style={{width:"6rem", height:"6rem", borderRadius:"50%"}} />
       }
       {!viewOnly?<div> <input
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={(event)=>{handleFileSelection(event)}}
          style={{ display: "none" }}
        />
        
        <span style={{backgroundColor:'primary', width:'120px', height:'120px', borderRadius:"20%", position:'relative', left:'30px', top:'-25px', zIndex:"999"}}>
        <i className="fa fa-camera me-2" aria-hidden="true" onClick={triggerFileSelectPopup} style={{ border:'2px solid white', borderRadius:'50%',color:'black', left:'-20px', top:'35px', fontSize:'20px', zIndex:'500', cursor:'pointer'}}></i>

        {/*<FontAwesomeIcon icon={faCamera} className="me-2" onClick={triggerFileSelectPopup} />*/}
            </span>
            </div>:null}
        </div>
      
    )

}