import React, {useState, useContext, useEffect} from 'react';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, Dropdown, Table, Modal } from 'react-bootstrap';
import {Helmet} from 'react-helmet-async';
import ReactToPrint from "react-to-print";
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSVLink } from "react-csv";
//import { faEye, faHome, faSearch, faEllipsisH, faEdit } from '@fortawesome/free-solid-svg-icons';
import { ProductContext } from '../../ProductProvider';
import { downloadHeaders, coursesOffered } from '../../ProData';
import Preloader from '../../components/Preloader/Preloader';
import RegistrationForm from '../registration/RegistrationForm';
import AcademicInfo from '../registration/AcademicInfo';
import CertificateCopy from '../registration/CertificateCopy';
import MarkListCopy from '../registration/MarkListCopy';
import RecommendationLetter from '../registration/RecommendationLetter';
import FileAttachments from '../registration/FileAttachments';
//import MarkSheetForm from '../course/MarkSheetForm';
import MarkSheet from '../course/MarkSheet';
import { Link } from "react-router-dom";
import DeactivateModal from '../registration/DeactivateModal';
import DocPhotoProof from '../registration/DocPhotoProof';

const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function StudentSearch() {
  const { loggedUser, generateDate, validateLogin, handleImageError } = useContext(ProductContext);
  const [searchTopic, setSearchTopic] = useState('Batch');
  const [searchValue, setSearchValue] = useState('');
  const [batchYear, setBatchYear]= useState([]);
  const [loaded, setLoaded] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const csvLinkEl = React.createRef();
  const componentRef = React.useRef(null);
  const [profileDetails, setProfileDetails]=useState({applicantName:'', fatherName:'', dateOfBirth:'', gender:'Male', nationality:'',
    religiousStatus:'Scholastic', parishDiocese:'', congregationProvince:'', areaCode:'+91',mobileNumber:'', emailID:'', placeOfBirth:'', 
    birthDistrict:'', birthState:'', localAddress:'', localState:'', localPincode:'', localContact:'', perAddress:'', perState:'', perPincode:'',
    perContactNo:'', courseCategory:'Bachelor of Theology', degreeDetails:[], languageDetails:[], courseDetails:[]});
  const [idProof, setIDProof]=useState('');
  const [certProofFile, setCertProofFile]=useState('');
  const [markListFile, setMarkListFile]=useState('');
  const [recommenLetterFile, setRecommenLetterFile]=useState('');
  const [collapsed, setCollapsed] = useState(true);
  const [newCollapsed, setNewCollapsed] = useState(true);
  const [deactivateCollapsed, setDeactivateCollapsed] = useState(true);
  const componentModelRef = React.useRef(null);
  const [markCollapsed, setMarkCollapsed] = useState(true);
  const [courseDetails, setCourseDetails]=useState({courseCode:'', courseName:''});
  const [formType, setFormType] = useState("View");
  const { enqueueSnackbar } = useSnackbar();
  const [imageFile, setImageFile] = useState('');
  const [batch, setBatch]= useState('24');
  const [course, setCourse] = useState('BTH');

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      processBatchYear();
    }else{
    validateLogin();
    }
  },[loggedUser])

  const processBatchYear=()=>{
    const current_datetime = new Date();
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    //const lastTenYear=date_year-10;
    var tbatchYear=[];
    for(var i=0; i<10;i++){
      tbatchYear.push(date_year-i);
    }

  //  console.log("batch year : ", tbatchYear);
    setBatchYear(tbatchYear);
    setSearchValue(date_year);
    setBatch(date_year);
    const tempInput=date_year.toString()+'BTH';
    setSearchValue(tempInput);

  }

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const reactToPrintContentModal = React.useCallback(() => {
    return componentModelRef.current;
  }, [componentModelRef.current]);

  const reactToPrintTriggerModal = React.useCallback(() => {
    return <Button   >Print</Button>;
  }, []);


  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  const toggle = () => {
         setCollapsed(!collapsed);
  }

  const newToggle = () => {
          setNewCollapsed(!newCollapsed);
    }

  const deactivateToggle=()=>{
    setDeactivateCollapsed(!deactivateCollapsed);
  }

  const handleMarkSheetView =(event, item)=>{
    event.preventDefault();
    setProfileDetails(item);
    markToggle();
  }

    const markToggle = () => {
      //   console.log("clicked");
            setMarkCollapsed(!markCollapsed);
      }
  
  const handleSearch=async()=>{
    //event.preventDefault();
    try{
      if(searchValue===""){
        enqueueSnackbar("Search Value is empty Enter the search input and then hit Search button!",{variant:'warning'}); 
      }else{
        setLoaded(false);
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
      await axios.get(`${config.api.invokeURL}/dashboard/student/search?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
        headers: {
          Authorization: idToken,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
       console.log("response : ", response.data[0]);
        if(response.data.length>0)
        {
            setSearchResults(response.data);
        }
        enqueueSnackbar(response.data.length + " - student's records found!",{variant:'success'}); 
        setLoaded(true);
    }).catch((error)=>{
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
  })
}
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
    }
  }

  const handleApplicationView=async (event, item)=>{
    event.preventDefault();
    try{
      //console.log("Item : ", item);
      const titem=item;
      if(titem.degreeDetails===undefined){
        titem.degreeDetail=[];
      }
      if(titem.idProofFile===undefined){
        titem.idProofFile="";
      }
      if(titem.certProofFile===undefined){
        titem.certProofFile="";
      }
      if(titem.avatar===undefined){
        titem.avatar="";
      }
    //  console.log("TITEM : " , titem);
      setProfileDetails(titem);
      const tapplicationNo= item.applicationNo
     // console.log("item : ", item.idproofFile);
     if(item.recommLetter===""||item.recommLetter===undefined||item.recommLetter===null){
       setRecommenLetterFile('');
     }else{
       const tIDProofName=item.recommLetter;
       await setRecommenLetterFile(`${config.s3.profileUrl}${tapplicationNo}${"/docs/"}${tIDProofName}`);
     }
     if(item.certproofFile===""||item.certproofFile===undefined||item.certproofFile===null){
       setCertProofFile('');
     }else{
    //  const tcertProofName=tapplicationNo+"_certproof.jpeg";
     const tcertProofName=item.certproofFile;
     await setCertProofFile(`${config.s3.profileUrl}${tapplicationNo}${"/docs/"}${tcertProofName}`);
     }
     if(item.markListFile===""||item.markListFile===undefined||item.markListFile===null){
       setMarkListFile('');
     }else{
       const tIDProofName=item.markListFile;
       await setMarkListFile(`${config.s3.profileUrl}${tapplicationNo}${"/docs/"}${tIDProofName}`);
     }
 
 toggle();
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
    }

  }

  const handleApplicationEdit=(event, item)=>{
    event.preventDefault();
    try{
      //console.log("Item : ", item);
      const titem=item;
      if(titem.degreeDetails===undefined){
        titem.degreeDetails=[];
      }
      if(titem.idproofFile===undefined){
        titem.idproofFile="";
      }
      if(titem.certproofFile===undefined){
        titem.certproofFile="";
      }
      if(titem.avatar===undefined){
        titem.avatar="";
      }
  //    console.log("TITEM : " , titem);
      setProfileDetails(titem);
      const tapplicationNo= item.applicationNo
      if(item.idproofFile===""||item.idproofFile===undefined||item.idproofFile===null){
        setIDProof('');
      }else{
        const tIDProofName=item.idproofFile;
       setIDProof(`${config.s3.profileUrl}${tapplicationNo}${"/docs/"}${tIDProofName}`);
      }
      if(item.certproofFile===""||item.certproofFile===undefined||item.certproofFile===null){
       setCertProofFile('');
      }else{
     //  const tcertProofName=tapplicationNo+"_certproof.jpeg";
      const tcertProofName=item.certproofFile;
      setCertProofFile(`${config.s3.profileUrl}${tapplicationNo}${"/docs/"}${tcertProofName}`);
      }
      newToggle();
    }catch(error){
      //console.log("Error : ", error);
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
    }

  }

  const postOnS3 = async(applicationno,timageFile,inputType)=>{
    try{
         // console.log("inputType : ", inputType);
        var tKeyName="";
        var newFileName="";
        if(inputType==="Profile"){
          tKeyName="/avatar/"+applicationno;   
          newFileName=applicationno+".jpeg";
        }else if (inputType==="Recomm"){
          tKeyName="/avatar/"+applicationno+"/docs";  
          newFileName=applicationno+"_recommLetter.jpeg"; 
        }else if (inputType==="CERTProof"){
          tKeyName="/avatar/"+applicationno+"/docs";  
          newFileName=applicationno+"_certproof.jpeg"; 
        }else if (inputType==="MarkList"){
          tKeyName="/avatar/"+applicationno+"/docs";  
          newFileName=applicationno+"_markproof.jpeg"; 
        }   
      
      //console.log("newfilename :", newFileName);
     // const newImage="";
      var file = timageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
     // console.log("New file : ", newFile);
      //const session = await Auth.currentSession();
      //const access_token=session.idToken.jwtToken;
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const bucketName=config.s3.bucketName + tKeyName;
     // await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
     // await axios.get(`${config.api.invokeURL}/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
      await axios.get(`${config.api.invokeURL}/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}`,{
           headers:{
               Authorization: idToken,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
         //  console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
           //  console.log("Res : ", res);
             if(res.status===200){
            //  setNewProfileImage(newFileName);
              //  fetchGalleryImages();
                // setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                // var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
             }
        }).catch((error)=>{
           enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
       })
    }catch(error){
     enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    }
  }

  const validateFields=()=>{
    const {applicantName, fatherName, dateOfBirth, gender, district, state, religiousStatus, parishDiocese, 
      congregationProvince, mobileNumber, emailID, placeOfBirth, localAddress, localState, 
      localPincode, nationality, localContact, perAddress, perState, perPincode, perContactNo, courseCategory,
      languageDetails, degreeDetails, birthDistrict, birthState }=profileDetails;
      var returnValue="passed";
      if(!emailID.match(validRegex)){
          enqueueSnackbar("Email address is not in right format!",{variant:'warning'});
      returnValue="failed";
      }else  if(applicantName===""){
      enqueueSnackbar("Name cannot be empty!",{variant:'warning'}); 
    returnValue="failed";
  }else  if(dateOfBirth===""){
      enqueueSnackbar("Date of Birth cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(gender===""){
      enqueueSnackbar("Gender cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(religiousStatus===""){
      enqueueSnackbar("Religious Status cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(birthDistrict===""||birthDistrict===undefined){
    enqueueSnackbar("Birth District cannot be Empty!",{variant:'warning'}); 
returnValue="failed";
}else  if(birthState===""||birthState===undefined){
  enqueueSnackbar("Bith State Status cannot be Empty!",{variant:'warning'}); 
returnValue="failed";
}else  if(fatherName===""){
      enqueueSnackbar("Father Name cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(district===""){
      enqueueSnackbar("District cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(mobileNumber===""){
      enqueueSnackbar("Mobile Number cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(emailID===""){
      enqueueSnackbar("Email Address cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(state===""){
      enqueueSnackbar("State cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(parishDiocese===""){
      enqueueSnackbar("Parish Diocese cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(congregationProvince===""){
      enqueueSnackbar("Congregation Province cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(nationality===""){
      enqueueSnackbar("Nationality cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(placeOfBirth===""){
      enqueueSnackbar("Place Of Birth cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(languageDetails===""){
      enqueueSnackbar("Language Details cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(localAddress===""||localState===""||localPincode===""||localContact===""){
      enqueueSnackbar("Local Address cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(perAddress===""||perState===""||perPincode===""||perContactNo===""){
      enqueueSnackbar("Permanent Address cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(degreeDetails===""){
      enqueueSnackbar("Degree Details cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  {
    returnValue="passed";
  }
  return returnValue;
  }

  const handleSubmit=async(event)=>{
    event.preventDefault();
    try{
    const validationResult=validateFields();
    if(validationResult==="passed"){
      setLoaded(false);
      const {applicantName, fatherName, dateOfBirth, gender, birthDistrict, birthState, religiousStatus, parishDiocese, 
        congregationProvince, areaCode, mobileNumber, emailID, placeOfBirth, localAddress, localState, 
        localPincode, nationality, localContact, perAddress, perState, perPincode, perContactNo, courseCategory,
        languageDetails, degreeDetails, registrationNo, applicationNo }=profileDetails;
        console.log("profile details:", profileDetails);
        const clientID = "VJCollege";
        const applicationno= applicationNo;
        //const applicationDate=await generateDate();
        //const applicationStatus="Pending Approval";
        const avatar= applicationno+".jpeg";
        const recommLetter=applicationno+"_recommLetter.jpeg"; 
        const certproofFile=applicationno+"_certproof.jpeg";  
        const markListFile=applicationno+"_markproof.jpeg";
        //const tcourseStatus="Pending Approval";
        //const tcoursePayment="Pending"; 
        const tdate=await generateDate();
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const params={
          clientID:clientID, applicationNo: applicationno, registrationNo:registrationNo,
          applicantName:applicantName,fatherName:fatherName, birthDistrict:birthDistrict, birthState:birthState, 
          dateOfBirth:dateOfBirth, gender:gender, religiousStatus:religiousStatus,
          parishDiocese:parishDiocese, congregationProvince:congregationProvince, placeOfBirth:placeOfBirth, 
          localAddress:localAddress, areaCode:areaCode, mobileNumber:mobileNumber, emailID:emailID, localState:localState, 
          localPincode:localPincode, localContact:localContact,
          perAddress:perAddress, nationality:nationality, perState:perState, perPincode:perPincode, 
          perContactNo:perContactNo, avatar:avatar, recommLetter:recommLetter, markListFile:markListFile,
          certproofFile:certproofFile, degreeDetails:degreeDetails, languageDetails:languageDetails,
          updatedBy:loggedUser.name, updatedOn:tdate};
      //console.log("Params : ", params);
      await axios.patch(`${config.api.invokeURL}/dashboard/registration/${applicationno}`, params,{
        headers: {
          Authorization: idToken,
          'x-api-key':config.api.key
        }})
        .then(async(response)=>{
          //console.log("response : ", response); 
       if(imageFile){
          await postOnS3(applicationno, imageFile,"Profile");
       }
       if(recommenLetterFile){
          await postOnS3(applicationno, recommenLetterFile,"Recomm");
       }
       if(certProofFile){
          await postOnS3(applicationno, certProofFile,"CERTProof");
       }
       if(markListFile){
        await postOnS3(applicationno, markListFile,"MarkList");
       }
       await handleSearch();
         enqueueSnackbar(applicationno + " - Successfully Updated.",{variant:'success'}); 
        window.scrollTo(0,0);
        newToggle();
        setLoaded(true);
        }).catch((error)=>{
          console.log("Error :", error);
          enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
          window.scrollTo(0,0);
          setLoaded(true);
        })
    }
      }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    window.scrollTo(0,0);
    setLoaded(true);
    }
  }

  const handleDeactivate=async(event,item)=>{
    event.preventDefault();
    setProfileDetails(item);
    deactivateToggle();
  }

  const processBatchInput = (ttinput, type)=>{
    //e.preventDefault();
    //console.log("e:",ttinput);
    var tempInput="";
    if(type==="Year"){
      tempInput=ttinput.toString()+course;
      setBatch(ttinput);
    }else{
      const tempFilter=coursesOffered.filter((item)=>item.courseName===ttinput);
      tempInput=batch.toString()+tempFilter[0].courseCode.toString();
      setCourse(tempFilter[0].courseCode);
    }
    //console.log("temp input :", tempInput);
    setSearchValue(tempInput);
  }

 

  return (
    <div>
      <Helmet>
      <title>Student Search - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Student Search - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Student Search - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
      <link rel="canonical" href="/student" />
    </Helmet>
    
    {!loaded && <Preloader show={loaded ? false : true} />}
    
    {/*View*/}
    <div >
      <Modal onHide={toggle} show={!collapsed} size="lg" >
      <div  ref={componentModelRef}>
          <Modal.Header  toggle={toggle}>
            Registration Form - View
                </Modal.Header>
                <Modal.Body >    
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div className="d-block mb-4 mb-md-0">
      </div>
      <div className="btn-toolbar mb-2 mb-md-0"> 
        <ButtonGroup>
        <ReactToPrint
      content={reactToPrintContent}
      documentTitle="student"
      trigger={reactToPrintTrigger}
    />
        {/*<Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
        <CSVLink
            headers={downloadHeaders}
            filename="download.csv"
            data={searchResults}
            ref={csvLinkEl}
  />*/}
        </ButtonGroup>
      </div>
    </div>
    <div ref={componentRef} style={{width:"100%", paddingLeft:"20px"}} >
                    <RegistrationForm  formType={"View"} profileDetails={profileDetails} setProfileDetails={setProfileDetails}/> 
                    <AcademicInfo formType={"View"} form={profileDetails} setForm={setProfileDetails} />  
                    <FileAttachments certProofFile={certProofFile} markListFile={markListFile} recommenLetterFile={recommenLetterFile}/>    
                    </div>
                </Modal.Body>
                <Modal.Footer>
        {' '}
        <ReactToPrint
    content={reactToPrintContentModal}
    documentTitle="AwesomeFileName"
    
    trigger={reactToPrintTriggerModal}
  />
        <Button onClick={toggle}>
            Close
        </Button>
        </Modal.Footer>
      </div>    
    </Modal>
    </div>

    {/*Edit*/}
    <Modal onHide={newToggle} show={!newCollapsed} size="lg">
                <Modal.Header toggle={newToggle}>
                Registration Form - Edit
                </Modal.Header>
                <Modal.Body>    
                <div>
                  <RegistrationForm formType={"Edit"} profileDetails={profileDetails} setProfileDetails={setProfileDetails} setImageFile={setImageFile}/>
                  <Row>
                          <AcademicInfo  formType={"Edit"} form={profileDetails} setForm={setProfileDetails} />
                        </Row>
                        <Row className="p-4">
                        <Col md={6}>
                          <DocPhotoProof setImageFile={setImageFile}/>
                          </Col>
                          <Col md={6}>
                          <CertificateCopy fileName="test" setCertProofFile={setCertProofFile}/>
                          </Col>
                          <Col md={6}>
                          <MarkListCopy fileName="test" setMarkListFile={setMarkListFile}/>
                          </Col>
                          <Col md={6}>
                          <RecommendationLetter fileName="test" setRecommenLetterFile={setRecommenLetterFile}/>
                          </Col>
                        </Row>
                        
                        <div className="mt-3 text-center">
                          <Button variant="primary" type="submit" onClick={(event)=>{handleSubmit(event)}}>Submit</Button>
                        </div>
                    
                  </div>
                </Modal.Body>
                <Modal.Footer>
        {' '}
        <Button onClick={newToggle}>
            Close
        </Button>
        </Modal.Footer>
    </Modal>

    {/*Marksheet*/}
    <Modal onHide={markToggle} show={!markCollapsed} size="lg" >
      <Modal.Header toggle={markToggle}>
      <Modal.Title>Mark - {formType}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <MarkSheet  courseDetails={courseDetails} setCourseDetails={setCourseDetails} profileDetails={profileDetails}/>
      </Modal.Body>
      <Modal.Footer> 
      {formType==="Add"?<Button
        color="primary"
       // onClick={handleSubmit}
      >
        Add Message       
        </Button>:formType==="Edit"?<Button
        color="primary"
      //  onClick={EditMessage}
      >
        Edit Message       
        </Button>:null}
  {' '}
      <Button onClick={markToggle}>
        Close
      </Button>
    </Modal.Footer>
    </Modal>  

    {/*DeActivate*/}
     <Modal show={!deactivateCollapsed} onHide={deactivateToggle}>
                    <Modal.Header toggle={deactivateToggle}>
                    De-Activate - {profileDetails.registrationNo}
                    </Modal.Header>
                    <Modal.Body>    
                    <div className="text-center">
                      <DeactivateModal profileDetails={profileDetails} deactivateToggle={deactivateToggle}/>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button onClick={deactivateToggle}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
  

        <Breadcrumb>
          <Breadcrumb.Item> <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link></Breadcrumb.Item>
          <Breadcrumb.Item>Students Details</Breadcrumb.Item>
          <Breadcrumb.Item active>Search</Breadcrumb.Item>
        </Breadcrumb>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div className="d-block mb-4 mb-md-0">

        <h4>Student Details</h4>
      </div>
      <div className="btn-toolbar mb-2 mb-md-0"> 
        <ButtonGroup>
        <ReactToPrint
      content={reactToPrintContent}
      documentTitle="student"
      trigger={reactToPrintTrigger}
    />
        <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
        <CSVLink
            headers={downloadHeaders}
            filename="download.csv"
            data={searchResults}
            ref={csvLinkEl}
          />
        </ButtonGroup>
      </div>
    </div>
    <div className="table-settings mb-4">
      <Row className="justify-content-start align-items-center">
        <Col lg={4} className="mb-3">
          <Form.Group id="searchstud">               
            <Form.Select defaultValue="Batch" value={searchTopic} 
            size="lg" style={{fontSize:16, borderRadius:10, padding:2, width:"100%"}}
            onChange={(event)=>{setSearchTopic(event.target.value)}}>
            <option value="Batch">Batch</option>
            <option value="RegistrationID">Registration No</option>
            <option value="ApplicationID">Application No</option>
              <option value="CourseStatus">Course Status</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col  className="mb-4" lg={6}>
            {searchTopic==="Batch"?  <div className='d-flex'>         
            <Form.Select 
            size="lg" style={{fontSize:16, borderRadius:10, padding:2, width:"100%"}}
                onChange={(event)=>{processBatchInput(event.target.value, "Year")}}>
                {batchYear.map(item => {
                  return (
                  <option key={item} value={item}>{item}</option>
                  );
                })}
          </Form.Select>  
           <Form.Select 
             style={{fontSize:16, borderRadius:10, padding:2, width:"100%"}}
                onChange={(event)=>{processBatchInput(event.target.value,"Course")}}>
                {coursesOffered.map(item => {
                  return (
                  <option key={item.courseID} value={item.courseName}>{item.courseName}</option>
                  );
                })}
          </Form.Select></div>:
          <Form.Control type="text" placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
        </Col>
        <Col  className="mb-3" lg={2}>
            <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
          </Col>

      </Row>
    </div>

    <Table ref={componentRef} hover responsive>
          <thead>
              <tr>
              <th>#</th>
              <th>{searchTopic==="ApplicationID"? "Application No": "Registration No"}</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Course Status</th>
              <th>Course</th>
              <th>Action</th>
              </tr>
          </thead>
          <tbody>
          {searchResults.map((item, index)=>
          <tr key={index}>
          <th scope="row">{index+1}</th>
          <td>{item.registrationNo}</td>
          <td><img id="profile" src={`${config.s3.profileUrl}${item.applicationNo}${"/"}${item.avatar}${"?t="} ${new Date().getTime()}`}
          onError={handleImageError}
          alt="Profile" style={{width:"3rem", height:"3rem", borderRadius:"50%"}} /> {item.applicantName}</td>
          <td>{item.areaCode}-{item.mobileNumber}</td>
          <td>{item.courseStatus==="Completed"?<p style={{backgroundColor:"blue", borderRadius:"10px", textAlign:"center", color:"white"}}>Completed</p>:
          item.courseStatus==="Approved"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Approved</p>:
          <p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>{item.courseStatus}</p>}</td>
          <td>{item.courseCategory===undefined?null:<p> {item.courseCategory}</p>}</td>
          <td>
          <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
            <span className="icon icon-sm">
            <i className="fa fa-ellipsis-h icon-dark" aria-hidden="true"></i>

              {/*<FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />*/}
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item)}}>
            <i className="fa fa-eye" aria-hidden="true">View Details</i>

             {/*} <FontAwesomeIcon icon={faEye} className="me-2" /> View Details*/}
            </Dropdown.Item>
            <Dropdown.Item  onClick={(event)=>{handleApplicationEdit(event, item)}}>
            <i className="fa fa-pencil" aria-hidden="true">Edit Details</i>

              {/*<FontAwesomeIcon icon={faEdit} className="me-2" /> Edit Details*/}
            </Dropdown.Item>
            <Dropdown.Item  onClick={(event)=>{handleMarkSheetView(event, item)}}>
            <i className="fa fa-list-ol" aria-hidden="true">Mark Sheet</i>

             {/*} <FontAwesomeIcon icon={faEdit} className="me-2" /> Mark Sheet*/}
            </Dropdown.Item>
            <Dropdown.Item  onClick={(event)=>{handleDeactivate(event, item)}}>
            <i className="fa fa-ban" aria-hidden="true"></i>De-Activate
            </Dropdown.Item>
            {/*<Dropdown.Item  onClick={(event)=>{viewJourneyToggle(item)}}>
              <FontAwesomeIcon icon={faEdit} className="me-2" /> View Journey
          </Dropdown.Item>*/}
          </Dropdown.Menu>
        </Dropdown>
          </td>
          </tr>)}
          </tbody>
    </Table>
  </div>
  )
}
