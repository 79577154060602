export const VJTodayList=[{
    id:20,
    issue:"Issue 20",
    title:"VJ Today",
    issueYear:"2023",
    issueMonth:"Dec",
  },{
      id:19,
      issue:"Issue 19",
      title:"VJ Today",
      issueYear:"2023",
      issueMonth:"Oct - Dec",
    },{
      id:18,
      issue:"Issue 18",
      title:"VJ Today",
      issueYear:"2023",
      issueMonth:"Apr",
    },{
      id:17,
      title:"VJ Today",
      issue:"Issue 17",
      issueYear:"2022",
      issueMonth:"Oct - Dec",
    },{
      id:16,
      title:"VJ Today",
      issue:"Issue 16",
      issueYear:"2022",
      issueMonth:"July - Sep",
    },{
      id:15,
      title:"VJ Today",
      issue:"Issue 15",
      issueYear:"2022",
      issueMonth:"Jan - Apr",
    },{
      id:14,
      title:"VJ Today",
      issue:"Issue 14",
      issueYear:"2021",
      issueMonth:"Oct - Dec",
    },{
      id:13,
      title:"VJ Today",
      issue:"Issue 13",
      issueYear:"2021",
      issueMonth:"July - Sep",
    },{
      id:12,
      title:"VJ Today",
      issue:"Issue 12",
      issueYear:"2021",
      issueMonth:"Jan - Mar",
    },{
      id:11,
      title:"VJ Today",
      issue:"Issue 11",
      issueYear:"2020",
      issueMonth:"Oct - Dec",
    },{
      id:10,
      title:"VJ Today",
      issue:"Issue 10",
      issueYear:"2020",
      issueMonth:"July - Sept",
    },{
      id:9,
      title:"VJ Today",
      issue:"Issue 9",
      issueYear:"2020",
      issueMonth:"Jan - Mar",
    },{
      id:8,
      title:"VJ Today",
      issue:"Issue 8",
      issueYear:"2019",
      issueMonth:"Oct - Dec",
    },{
      id:7,
      title:"VJ Today",
      issue:"Issue 7",
      issueYear:"2019",
      issueMonth:"July - Sept",
    },{
      id:6,
      title:"VJ Today",
      issue:"Issue 6",
      issueYear:"2019",
      issueMonth:"Jan - Mar",
    },{
      id:5,
      title:"VJ Today",
      issue:"Issue 5",
      issueYear:"2018",
      issueMonth:"Oct - Dec",
    },{
      id:4,
      title:"VJ Today",
      issue:"Issue 4",
      issueYear:"2018",
      issueMonth:"July - Sept",
    },{
      id:3,
      title:"VJ Today",
      issue:"Issue 3",
      issueYear:"2018",
      issueMonth:"Jan - Mar",
    },{
      id:2,
      title:"VJ Today",
      issue:"Issue 2",
      issueYear:"2017",
      issueMonth:"Oct - Dec",
    },{
      id:1,
      title:"VJ Today",
      issue:"Issue 1",
      issueYear:"2017",
      issueMonth:"July - Sept",
    }];

    export const coursesOffered=[{
      "courseID":"VJD-01",
      "courseName":"Bachelor of Theology",
      "courseCode":"BTH",
      "duration":"24 months",
      "fees":7000,
      "courseOption":"Correspondence",
      "payOption":"Full",
      "feesOpted":7000
    },{
      "courseID":"VJD-03",
      "courseName":"Licenciate in Sacred Theology",
      "courseCode":"LTH",
      "duration":"24 months",
      "fees":7000,
      "courseOption":"Correspondence",
      "payOption":"Full",
      "feesOpted":7000
    },{
      "courseID":"VJD-04",
      "courseName":"Doctorate of Theology",
      "courseCode":"DTH",
      "duration":"24 months",
      "fees":7000,
      "courseOption":"Correspondence",
      "payOption":"Full",
      "feesOpted":7000
    }];

    export const courseDetailsList=[
      {
        "courseId": 1,
        "courseCode": "BM",
        "courseName": "Self-Awareness - I & II",
        "courseCredit": 2,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 2,
        "courseCode": "BD",
        "courseName": "Intro to Theology & Social Analysis",
        "courseCredit": 3,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 3,
        "courseCode": "BS",
        "courseName": "Introduction to Scripture",
        "courseCredit": 2,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 4,
        "courseCode": "BD",
        "courseName": "Faith & Revelation",
        "courseCredit": 2,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 5,
        "courseCode": "BD",
        "courseName": "Symbols in Religions",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 6,
        "courseCode": "BI",
        "courseName": "Sociology of Religion",
        "courseCredit": 2,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 7,
        "courseCode": "BS",
        "courseName": "Pentateuch",
        "courseCredit": 2,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 8,
        "courseCode": "BI",
        "courseName": "Popular Islam",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 9,
        "courseCode": "BS",
        "courseName": "Introduction to New Testament",
        "courseCredit": 2,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 10,
        "courseCode": "BD",
        "courseName": "Christian Anthropology and  Grace",
        "courseCredit": 3,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 11,
        "courseCode": "BM",
        "courseName": "Fundamental Moral Theology",
        "courseCredit": 2,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 12,
        "courseCode": "BS",
        "courseName": "Historical Books",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 13,
        "courseCode": "BI",
        "courseName": "Popular Hindu Religiosity",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 14,
        "courseCode": "BM",
        "courseName": "Social Teachings of the  Church",
        "courseCredit": 2,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 15,
        "courseCode": "BS",
        "courseName": "Gospel of Mark",
        "courseCredit": 2,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 16,
        "courseCode": "BH",
        "courseName": "Early Christianity and Patrology",
        "courseCredit": 2,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 17,
        "courseCode": "BD",
        "courseName": "Feminist Theology",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 18,
        "courseCode": "BD",
        "courseName": "Dalit Questions",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 19,
        "courseCode": "BD",
        "courseName": "Tribal Theology",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 20,
        "courseCode": "IA1",
        "courseName": "Integral Assessment - Year I",
        "courseCredit": 2,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 21,
        "courseCode": "oi",
        "courseName": "Sufism",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Optional",
        "totalMarks": 100
      },
      {
        "courseId": 22,
        "courseCode": "oi",
        "courseName": "Indian Constiution - Christian Perspective",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Optional",
        "totalMarks": 100
      },
      {
        "courseId": 23,
        "courseCode": "jd",
        "courseName": "Judaism",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Optional",
        "totalMarks": 100
      },
      {
        "courseId": 24,
        "courseCode": "is",
        "courseName": "Ignatian Spirituality",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Optional",
        "totalMarks": 100
      },
      {
        "courseId": 25,
        "courseCode": "os",
        "courseName": "Biblical Themes",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Optional",
        "totalMarks": 100
      },
      {
        "courseId": 26,
        "courseCode": "BS",
        "courseName": "Prophets",
        "courseCredit": 2,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 27,
        "courseCode": "BS",
        "courseName": "Pauline Corpus",
        "courseCredit": 3,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 28,
        "courseCode": "BS",
        "courseName": "Gospel of John",
        "courseCredit": 2,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 29,
        "courseCode": "BD",
        "courseName": "Trinity",
        "courseCredit": 1,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 30,
        "courseCode": "BI",
        "courseName": "Christian-Muslim Dialogue",
        "courseCredit": 1,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 31,
        "courseCode": "BD",
        "courseName": "Christology",
        "courseCredit": 3,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 32,
        "courseCode": "BS",
        "courseName": "Acts of the Apostles",
        "courseCredit": 1,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 33,
        "courseCode": "BD",
        "courseName": "Ecclesiology",
        "courseCredit": 2,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 34,
        "courseCode": "BI",
        "courseName": "Mediating Figures",
        "courseCredit": 1,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 35,
        "courseCode": "BI",
        "courseName": "Study of Indian Religious Texts",
        "courseCredit": 1,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 36,
        "courseCode": "BS",
        "courseName": "Gospel of Mathew",
        "courseCredit": 2,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 37,
        "courseCode": "BM",
        "courseName": "Canon Law - General Norms",
        "courseCredit": 2,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 38,
        "courseCode": "BD",
        "courseName": "Mariology",
        "courseCredit": 1,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 39,
        "courseCode": "BD",
        "courseName": "Baptism & Confirmation",
        "courseCredit": 1,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 40,
        "courseCode": "BH",
        "courseName": "Church in Middle Ages, Modern and Contemporary Times",
        "courseCredit": 3,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 41,
        "courseCode": "BM",
        "courseName": "Theology of Marriage & Sexual Ethics",
        "courseCredit": 2,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 42,
        "courseCode": "BD",
        "courseName": "Eucharist",
        "courseCredit": 2,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 43,
        "courseCode": "BD",
        "courseName": "Pneumatology",
        "courseCredit": 1,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 44,
        "courseCode": "VA",
        "courseName": "II - Vatican Documents",
        "courseCredit": 3,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 45,
        "courseCode": "ie",
        "courseName": "Integral Ecology",
        "courseCredit": 1,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 46,
        "courseCode": "hb",
        "courseName": "Lettter to Hebrews",
        "courseCredit": 1,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Optional",
        "totalMarks": 100
      },
      {
        "courseId": 47,
        "courseCode": "oi",
        "courseName": "Sikhism",
        "courseCredit": 1,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Optional",
        "totalMarks": 100
      },
      {
        "courseId": 48,
        "courseCode": "om",
        "courseName": "Child care Ethics",
        "courseCredit": 1,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Optional",
        "totalMarks": 100
      },
      {
        "courseId": 49,
        "courseCode": "BD",
        "courseName": "Spirituality",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 50,
        "courseCode": "BM",
        "courseName": "Marriage Law",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 51,
        "courseCode": "BD",
        "courseName": "Priesthood",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 52,
        "courseCode": "BM",
        "courseName": "Liturgy",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 53,
        "courseCode": "BD",
        "courseName": "Eschatology",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 54,
        "courseCode": "BM",
        "courseName": "Reconciliation & Anointing",
        "courseCredit": 2,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 55,
        "courseCode": "BH",
        "courseName": "Indian Church History",
        "courseCredit": 2,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 56,
        "courseCode": "BM",
        "courseName": "Bio-Medical Ethics",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 57,
        "courseCode": "BM",
        "courseName": "Human and Christian Virtues",
        "courseCredit": 2,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 58,
        "courseCode": "BD",
        "courseName": "Integral Mission",
        "courseCredit": 2,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 59,
        "courseCode": "BM",
        "courseName": "Pastoral Counseling",
        "courseCredit": 2,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 60,
        "courseCode": "BD",
        "courseName": "Oriental Theology",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 61,
        "courseCode": "BS",
        "courseName": "Wisdom Literature",
        "courseCredit": 2,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 62,
        "courseCode": "BM",
        "courseName": "Parish Life: History, Theology and Canon Law",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 63,
        "courseCode": "BS",
        "courseName": "Gospel of Luke",
        "courseCredit": 2,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 64,
        "courseCode": "BI",
        "courseName": "Awaking among Religions in India: Modern Phase",
        "courseCredit": 2,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 65,
        "courseCode": "BD",
        "courseName": "Ecumenism",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 66,
        "courseCode": "BS",
        "courseName": "Psalms",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 67,
        "courseCode": "hl",
        "courseName": "Homiletics",
        "courseCredit": 2,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Optional",
        "totalMarks": 100
      },
      {
        "courseId": 68,
        "courseCode": "pl",
        "courseName": "Pastoral Liturgy",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Optional",
        "totalMarks": 100
      },
      {
        "courseId": 69,
        "courseCode": "os",
        "courseName": "Revelation",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Optional",
        "totalMarks": 100
      },
      {
        "courseId": 70,
        "courseCode": "od",
        "courseName": "Feminist Spirituality",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Optional",
        "totalMarks": 100
      },
      {
        "courseId": 71,
        "courseCode": "ca",
        "courseName": "Canon Law for Religious",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Optional",
        "totalMarks": 100
      },
      {
        "courseId": 72,
        "courseCode": "cs",
        "courseName": "Canon Law on Sacraments",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Optional",
        "totalMarks": 100
      },
      {
        "courseId": 73,
        "courseCode": "pr",
        "courseName": "Peace and Reconciliation",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Optional",
        "totalMarks": 100
      },
      {
        "courseId": 74,
        "courseCode": "BM",
        "courseName": "BM - Seminar",
        "courseCredit": 3,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 75,
        "courseCode": "AAA",
        "courseName": "Dissertation",
        "courseCredit": 3,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 76,
        "courseCode": "FEX",
        "courseName": "COMPREHENSIVE EXAM MARKS",
        "courseCredit": 1,
        "courseYear": "Final",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 77,
        "courseCode": "JD",
        "courseName": "Introduction to Dead Sea Scroll",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 78,
        "courseCode": "GK",
        "courseName": "Greek",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 79,
        "courseCode": "HB",
        "courseName": "Hebrew",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 80,
        "courseCode": "Lt",
        "courseName": "Latin",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 81,
        "courseCode": "IA2",
        "courseName": "Integral Assessment - Year 2",
        "courseCredit": 1,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 82,
        "courseCode": "Tl",
        "courseName": "Theology of Laity",
        "courseCredit": 1,
        "courseYear": "Second",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },
      {
        "courseId": 83,
        "courseCode": "PT",
        "courseName": "Pastoral Theology",
        "courseCredit": 1,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Optional",
        "totalMarks": 100
      },{
        "courseId": 84,
        "courseCode": "BM",
        "courseName": "BM - Ad Auds",
        "courseCredit": 2,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 85,
        "courseCode": "LTH1",
        "courseName": "Research Methodology & Hermeneutics of Contextual Theological Methods",
        "courseCredit": 4,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 86,
        "courseCode": "LTH2",
        "courseName": "Modern Trends Theological Anthropology",
        "courseCredit": 4,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 87,
        "courseCode": "LTH3",
        "courseName": "Trends in Trinitarian Theology",
        "courseCredit": 4,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 88,
        "courseCode": "LTH4",
        "courseName": "Contemporary Issues in Christology",
        "courseCredit": 4,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 89,
        "courseCode": "LTH5",
        "courseName": "Biblical Hermeneutics",
        "courseCredit": 4,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 90,
        "courseCode": "LTH6",
        "courseName": "Theology of Councils: Emphasis on Vat II",
        "courseCredit": 4,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 91,
        "courseCode": "LTH7",
        "courseName": "Exploring Sacraments & Indian Samskaras",
        "courseCredit": 4,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 92,
        "courseCode": "LTH8",
        "courseName": "Theology of Religions",
        "courseCredit": 4,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 93,
        "courseCode": "LTH9",
        "courseName": "Contemporary Trends in Ecclesiology",
        "courseCredit": 4,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 94,
        "courseCode": "LTH10",
        "courseName": "Indian Christology: Methods & Issues",
        "courseCredit": 4,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 95,
        "courseCode": "LTH11",
        "courseName": "Peace & Reconciliation in the Bible",
        "courseCredit": 4,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 96,
        "courseCode": "LTH12",
        "courseName": "Political Theology: Public & Subaltern & Liberation Theology",
        "courseCredit": 4,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 97,
        "courseCode": "LTH13",
        "courseName": "Issues in Bio - Ethics & Guarding the Vulnerable Adults",
        "courseCredit": 4,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 98,
        "courseCode": "LTH14",
        "courseName": "Integral Ecology/Mariology (Constructive Ways)",
        "courseCredit": 4,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 99,
        "courseCode": "LTH15",
        "courseName": "Mission as Inculturation, New Evangelization & Inter Religious",
        "courseCredit": 4,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 100,
        "courseCode": "LTH16",
        "courseName": "Guided Paper (I)",
        "courseCredit": 10,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 101,
        "courseCode": "LTH17",
        "courseName": "Guided Paper (II)",
        "courseCredit": 10,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 102,
        "courseCode": "LTH18",
        "courseName": "Immersion Programme (I)",
        "courseCredit": 6,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 103,
        "courseCode": "LTH19",
        "courseName": "Immersion Programme (II)",
        "courseCredit": 6,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 104,
        "courseCode": "LTH20",
        "courseName": "DISSERTATION",
        "courseCredit": 30,
        "courseYear": "First",
        "degreeCode":"LTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 105,
        "courseCode": "SS",
        "courseName": "Symbols & Sacraments",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 106,
        "courseCode": "IR",
        "courseName": "Religions in Modern India",
        "courseCredit": 2,
        "courseYear": "Third",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      },{
        "courseId": 107,
        "courseCode": "FQ",
        "courseName": "Feminist Question",
        "courseCredit": 1,
        "courseYear": "First",
        "degreeCode":"BTH",
        "courseType": "Regular",
        "totalMarks": 100
      }
    ];

    export const downloadHeaders = [
      { label: "Title", key: "title" },
      { label: "Name", key: "applicantName" },
      { label: "Registration No", key: "registrationNo" },
      { label: "Application No", key: "applicationNo" },
      { label: "Application Status", key: "applicationStatus" },
      { label: "Application Date", key: "applicationDate" },
      { label: "Email", key: "emailID" },
      { label: "Mobile", key: "mobileNumber" },
      { label: "Mother Tongue", key: "motherTongue" },
      { label: "State", key: "addressState" },
      { label: "Gender", key: "gender" },
      { label: "Maritial Status", key: "maritial Status" },
      { label: "Religion", key: "religion" }
    ];

  export const phoneCode=[
      {
        "Country": "USA",
        "Code": "+1"
      },
      {
        "Country": "RUS",
        "Code": "+7"
      },
      {
        "Country": "EGY",
        "Code": "+20"
      },
      {
        "Country": "ZAF",
        "Code": "+27"
      },
      {
        "Country": "GRC",
        "Code": "+30"
      },
      {
        "Country": "NLD",
        "Code": "+31"
      },
      {
        "Country": "BEL",
        "Code": "+32"
      },
      {
        "Country": "FRA",
        "Code": "+33"
      },
      {
        "Country": "ESP",
        "Code": "+34"
      },
      {
        "Country": "HUN",
        "Code": "+36"
      },
      {
        "Country": "ITA",
        "Code": "+39"
      },
      {
        "Country": "ROU",
        "Code": "+40"
      },
      {
        "Country": "CHE",
        "Code": "+41"
      },
      {
        "Country": "AUT",
        "Code": "+43"
      },
      {
        "Country": "GBR",
        "Code": "+44"
      },
      {
        "Country": "DNK",
        "Code": "+45"
      },
      {
        "Country": "SWE",
        "Code": "+46"
      },
      {
        "Country": "NOR",
        "Code": "+47"
      },
      {
        "Country": "POL",
        "Code": "+48"
      },
      {
        "Country": "DEU",
        "Code": "+49"
      },
      {
        "Country": "PER",
        "Code": "+51"
      },
      {
        "Country": "MEX",
        "Code": "+52"
      },
      {
        "Country": "CUB",
        "Code": "+53"
      },
      {
        "Country": "ARG",
        "Code": "+54"
      },
      {
        "Country": "BRA",
        "Code": "+55"
      },
      {
        "Country": "CHL",
        "Code": "+56"
      },
      {
        "Country": "COL",
        "Code": "+57"
      },
      {
        "Country": "VEN",
        "Code": "+58"
      },
      {
        "Country": "MYS",
        "Code": "+60"
      },
      {
        "Country": "AUS",
        "Code": "+61"
      },
      {
        "Country": "IDN",
        "Code": "+62"
      },
      {
        "Country": "PHL",
        "Code": "+63"
      },
      {
        "Country": "NZL",
        "Code": "+64"
      },
      {
        "Country": "SGP",
        "Code": "+65"
      },
      {
        "Country": "THA",
        "Code": "+66"
      },
      {
        "Country": "JPN",
        "Code": "+81"
      },
      {
        "Country": "KOR",
        "Code": "+82"
      },
      {
        "Country": "VNM",
        "Code": "+84"
      },
      {
        "Country": "CHN",
        "Code": "+86"
      },
      {
        "Country": "TUR",
        "Code": "+90"
      },
      {
        "Country": "IND",
        "Code": "+91"
      },
      {
        "Country": "PAK",
        "Code": "+92"
      },
      {
        "Country": "AFG",
        "Code": "+93"
      },
      {
        "Country": "LKA",
        "Code": "+94"
      },
      {
        "Country": "MMR",
        "Code": "+95"
      },
      {
        "Country": "IRN",
        "Code": "+98"
      },
      {
        "Country": "SSD",
        "Code": "+211"
      },
      {
        "Country": "ESH",
        "Code": "+212"
      },
      {
        "Country": "DZA",
        "Code": "+213"
      },
      {
        "Country": "TUN",
        "Code": "+216"
      },
      {
        "Country": "LBY",
        "Code": "+218"
      },
      {
        "Country": "GMB",
        "Code": "+220"
      },
      {
        "Country": "SEN",
        "Code": "+221"
      },
      {
        "Country": "MRT",
        "Code": "+222"
      },
      {
        "Country": "MLI",
        "Code": "+223"
      },
      {
        "Country": "GIN",
        "Code": "+224"
      },
      {
        "Country": "CIV",
        "Code": "+225"
      },
      {
        "Country": "BFA",
        "Code": "+226"
      },
      {
        "Country": "NER",
        "Code": "+227"
      },
      {
        "Country": "TGO",
        "Code": "+228"
      },
      {
        "Country": "BEN",
        "Code": "+229"
      },
      {
        "Country": "MUS",
        "Code": "+230"
      },
      {
        "Country": "LBR",
        "Code": "+231"
      },
      {
        "Country": "SLE",
        "Code": "+232"
      },
      {
        "Country": "GHA",
        "Code": "+233"
      },
      {
        "Country": "NGA",
        "Code": "+234"
      },
      {
        "Country": "TCD",
        "Code": "+235"
      },
      {
        "Country": "CAF",
        "Code": "+236"
      },
      {
        "Country": "CMR",
        "Code": "+237"
      },
      {
        "Country": "CPV",
        "Code": "+238"
      },
      {
        "Country": "STP",
        "Code": "+239"
      },
      {
        "Country": "GNQ",
        "Code": "+240"
      },
      {
        "Country": "GAB",
        "Code": "+241"
      },
      {
        "Country": "COG",
        "Code": "+242"
      },
      {
        "Country": "COD",
        "Code": "+243"
      },
      {
        "Country": "AGO",
        "Code": "+244"
      },
      {
        "Country": "GNB",
        "Code": "+245"
      },
      {
        "Country": "IOT",
        "Code": "+246"
      },
      {
        "Country": "SYC",
        "Code": "+248"
      },
      {
        "Country": "SDN",
        "Code": "+249"
      },
      {
        "Country": "RWA",
        "Code": "+250"
      },
      {
        "Country": "ETH",
        "Code": "+251"
      },
      {
        "Country": "SOM",
        "Code": "+252"
      },
      {
        "Country": "DJI",
        "Code": "+253"
      },
      {
        "Country": "KEN",
        "Code": "+254"
      },
      {
        "Country": "TZA",
        "Code": "+255"
      },
      {
        "Country": "UGA",
        "Code": "+256"
      },
      {
        "Country": "BDI",
        "Code": "+257"
      },
      {
        "Country": "MOZ",
        "Code": "+258"
      },
      {
        "Country": "ZMB",
        "Code": "+260"
      },
      {
        "Country": "MDG",
        "Code": "+261"
      },
      {
        "Country": "REU",
        "Code": "+262"
      },
      {
        "Country": "ZWE",
        "Code": "+263"
      },
      {
        "Country": "NAM",
        "Code": "+264"
      },
      {
        "Country": "MWI",
        "Code": "+265"
      },
      {
        "Country": "LSO",
        "Code": "+266"
      },
      {
        "Country": "BWA",
        "Code": "+267"
      },
      {
        "Country": "SWZ",
        "Code": "+268"
      },
      {
        "Country": "COM",
        "Code": "+269"
      },
      {
        "Country": "SHN",
        "Code": "+290"
      },
      {
        "Country": "ERI",
        "Code": "+291"
      },
      {
        "Country": "ABW",
        "Code": "+297"
      },
      {
        "Country": "FRO",
        "Code": "+298"
      },
      {
        "Country": "GRL",
        "Code": "+299"
      },
      {
        "Country": "GIB",
        "Code": "+350"
      },
      {
        "Country": "PRT",
        "Code": "+351"
      },
      {
        "Country": "LUX",
        "Code": "+352"
      },
      {
        "Country": "IRL",
        "Code": "+353"
      },
      {
        "Country": "ISL",
        "Code": "+354"
      },
      {
        "Country": "ALB",
        "Code": "+355"
      },
      {
        "Country": "MLT",
        "Code": "+356"
      },
      {
        "Country": "CYP",
        "Code": "+357"
      },
      {
        "Country": "FIN",
        "Code": "+358"
      },
      {
        "Country": "BGR",
        "Code": "+359"
      },
      {
        "Country": "LTU",
        "Code": "+370"
      },
      {
        "Country": "LVA",
        "Code": "+371"
      },
      {
        "Country": "EST",
        "Code": "+372"
      },
      {
        "Country": "MDA",
        "Code": "+373"
      },
      {
        "Country": "ARM",
        "Code": "+374"
      },
      {
        "Country": "BLR",
        "Code": "+375"
      },
      {
        "Country": "AND",
        "Code": "+376"
      },
      {
        "Country": "MCO",
        "Code": "+377"
      },
      {
        "Country": "SMR",
        "Code": "+378"
      },
      {
        "Country": "UKR",
        "Code": "+380"
      },
      {
        "Country": "SRB",
        "Code": "+381"
      },
      {
        "Country": "MNE",
        "Code": "+382"
      },
      {
        "Country": "XKX",
        "Code": "+383"
      },
      {
        "Country": "HRV",
        "Code": "+385"
      },
      {
        "Country": "SVN",
        "Code": "+386"
      },
      {
        "Country": "BIH",
        "Code": "+387"
      },
      {
        "Country": "MKD",
        "Code": "+389"
      },
      {
        "Country": "CZE",
        "Code": "+420"
      },
      {
        "Country": "SVK",
        "Code": "+421"
      },
      {
        "Country": "LIE",
        "Code": "+423"
      },
      {
        "Country": "FLK",
        "Code": "+500"
      },
      {
        "Country": "BLZ",
        "Code": "+501"
      },
      {
        "Country": "GTM",
        "Code": "+502"
      },
      {
        "Country": "SLV",
        "Code": "+503"
      },
      {
        "Country": "HND",
        "Code": "+504"
      },
      {
        "Country": "NIC",
        "Code": "+505"
      },
      {
        "Country": "CRI",
        "Code": "+506"
      },
      {
        "Country": "PAN",
        "Code": "+507"
      },
      {
        "Country": "SPM",
        "Code": "+508"
      },
      {
        "Country": "HTI",
        "Code": "+509"
      },
      {
        "Country": "BLM",
        "Code": "+590"
      },
      {
        "Country": "BOL",
        "Code": "+591"
      },
      {
        "Country": "GUY",
        "Code": "+592"
      },
      {
        "Country": "ECU",
        "Code": "+593"
      },
      {
        "Country": "PRY",
        "Code": "+595"
      },
      {
        "Country": "SUR",
        "Code": "+597"
      },
      {
        "Country": "URY",
        "Code": "+598"
      },
      {
        "Country": "ANT",
        "Code": "+599"
      },
      {
        "Country": "TLS",
        "Code": "+670"
      },
      {
        "Country": "ATA",
        "Code": "+672"
      },
      {
        "Country": "BRN",
        "Code": "+673"
      },
      {
        "Country": "NRU",
        "Code": "+674"
      },
      {
        "Country": "PNG",
        "Code": "+675"
      },
      {
        "Country": "TON",
        "Code": "+676"
      },
      {
        "Country": "SLB",
        "Code": "+677"
      },
      {
        "Country": "VUT",
        "Code": "+678"
      },
      {
        "Country": "FJI",
        "Code": "+679"
      },
      {
        "Country": "PLW",
        "Code": "+680"
      },
      {
        "Country": "WLF",
        "Code": "+681"
      },
      {
        "Country": "COK",
        "Code": "+682"
      },
      {
        "Country": "NIU",
        "Code": "+683"
      },
      {
        "Country": "WSM",
        "Code": "+685"
      },
      {
        "Country": "KIR",
        "Code": "+686"
      },
      {
        "Country": "NCL",
        "Code": "+687"
      },
      {
        "Country": "TUV",
        "Code": "+688"
      },
      {
        "Country": "PYF",
        "Code": "+689"
      },
      {
        "Country": "TKL",
        "Code": "+690"
      },
      {
        "Country": "FSM",
        "Code": "+691"
      },
      {
        "Country": "MHL",
        "Code": "+692"
      },
      {
        "Country": "PRK",
        "Code": "+850"
      },
      {
        "Country": "HKG",
        "Code": "+852"
      },
      {
        "Country": "MAC",
        "Code": "+853"
      },
      {
        "Country": "KHM",
        "Code": "+855"
      },
      {
        "Country": "LAO",
        "Code": "+856"
      },
      {
        "Country": "BGD",
        "Code": "+880"
      },
      {
        "Country": "TWN",
        "Code": "+886"
      },
      {
        "Country": "MDV",
        "Code": "+960"
      },
      {
        "Country": "LBN",
        "Code": "+961"
      },
      {
        "Country": "JOR",
        "Code": "+962"
      },
      {
        "Country": "SYR",
        "Code": "+963"
      },
      {
        "Country": "IRQ",
        "Code": "+964"
      },
      {
        "Country": "KWT",
        "Code": "+965"
      },
      {
        "Country": "SAU",
        "Code": "+966"
      },
      {
        "Country": "YEM",
        "Code": "+967"
      },
      {
        "Country": "OMN",
        "Code": "+968"
      },
      {
        "Country": "PSE",
        "Code": "+970"
      },
      {
        "Country": "ARE",
        "Code": "+971"
      },
      {
        "Country": "ISR",
        "Code": "+972"
      },
      {
        "Country": "BHR",
        "Code": "+973"
      },
      {
        "Country": "QAT",
        "Code": "+974"
      },
      {
        "Country": "BTN",
        "Code": "+975"
      },
      {
        "Country": "MNG",
        "Code": "+976"
      },
      {
        "Country": "NPL",
        "Code": "+977"
      },
      {
        "Country": "TJK",
        "Code": "+992"
      },
      {
        "Country": "TKM",
        "Code": "+993"
      },
      {
        "Country": "AZE",
        "Code": "+994"
      },
      {
        "Country": "GEO",
        "Code": "+995"
      },
      {
        "Country": "KGZ",
        "Code": "+996"
      },
      {
        "Country": "UZB",
        "Code": "+998"
      }
    ];