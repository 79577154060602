import React, {useState, useEffect} from 'react'
import {Card, Row, Col, Form } from 'react-bootstrap';
import { phoneCode } from '../../ProData';
import ProfileImage from '../../components/profile/ProfileImages';
const config = require('../../config.json');

export default function RegistrationForm(props) {
    const {profileDetails, setProfileDetails, formType, setImageFile} = props;
    const [profileImage,setProfileImage]=useState('');
    const [viewOnly, setViewOnly] = useState(true);
    //const componentRef = React.useRef(null);

    useEffect(()=>{
        if(profileDetails.avatar===undefined||profileDetails.avatar===""){
          setProfileImage("https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg");
        }else{
          const tapplicationNo=profileDetails.applicationNo;
          const tImageName=profileDetails.avatar;
          setProfileImage(`${config.s3.profileUrl}${tapplicationNo}${"/"}${tImageName}`);  
          //console.log("test : ", `${config.s3.profileUrl}${tapplicationNo}${"/"}${tImageName}`);
        }
        if(formType==="Edit"){
          setViewOnly(false);
        }else{
          setViewOnly(true);
        }
      },[])
  
  return (
    <div>
    {profileImage && <ProfileImage formType={!formType} profileImage={profileImage} setImageFile={setImageFile}/>}
    <Card style={{padding:"0px"}}>
      <Card.Body>
        <Form>
        <Row>
            <Col lg={12} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Degree Program</Form.Label>
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                    value={profileDetails.courseCategory} disabled={viewOnly}
                    onChange={(event)=>{setProfileDetails({...profileDetails, courseCategory:event.target.value})}}>
                  <option value="Bachelor of Theology">Bachelor of Theology</option>
                  <option value="Master of Theology">Master of Theology</option>
                  <option value="Licentiate in Sacred Theology">Licentiate in Sacred Theology</option>
                  <option value="Doctorate of Theology">Doctorate of Theology</option>
                </Form.Select>
              </Form.Group>
            </Col>
            {/*<Col lg={6} className="mb-3">
              <Form.Group id="applicantName">
                <Form.Label>Application Date</Form.Label>
                <Form.Control required type="date" value={profileDetails.applicationDate}
                                   disabled={viewOnly}
                                   onChange={(event)=>{setProfileDetails({...profileDetails, applicantName:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>*/}
          </Row> 
          <Row>
            <Col lg={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Student Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter your name...." value={profileDetails.applicantName}
                  disabled={viewOnly}
                  onChange={(event)=>{setProfileDetails({...profileDetails, applicantName:(event.target.value).toUpperCase()})}}  
               />
              </Form.Group>
            </Col>
            <Col lg={6} className="mb-3">
              <Form.Group id="middleName">
                <Form.Label>Father Name</Form.Label>
                <Form.Control required type="text" value={profileDetails.fatherName}
                  placeholder='Enter Father name....' disabled={viewOnly}
                  onChange={(event)=>{setProfileDetails({...profileDetails, fatherName:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Email</Form.Label>
                <Form.Control required type="email" value={profileDetails.emailID}
                  disabled={viewOnly} placeholder='Enter your email address...'
                  onChange={(event)=>{setProfileDetails({...profileDetails, emailID:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col lg={6} className="mb-3">
            <Form.Label>Mobile Number</Form.Label>
            <Row>
              <Col sm={4} className="mb-3">
              <Form.Group id="areanumber">
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                type="select" name="courseName"
                                  value={profileDetails.areaCode} 
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, areaCode:event.target.value})}}
                                >
                                  {phoneCode.map(item => {
                                return (<option key={item.Code} value={item.Code}>{item.Code}</option>);
                              })}
                  </Form.Select>
                 </Form.Group>
            </Col>
             <Col sm={8} className="mb-3">
              <Form.Group id="mnumber">
                <Form.Control required type="number" placeholder="Enter your mobile number...." 
                                disabled={viewOnly}
                                value={profileDetails.mobileNumber} onChange={(event)=>{setProfileDetails({...profileDetails, mobileNumber:event.target.value})}}
                />
              </Form.Group>
            </Col>
            </Row>
            </Col>    
          </Row>
          <Row className="align-items-center">
            <Col lg={4} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label>Date Of Birth</Form.Label>
                <Form.Control type="date" value={profileDetails.dateOfBirth}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, dateOfBirth:event.target.value})}}
               />
              </Form.Group>
            </Col>
            <Col lg={4} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>Gender</Form.Label> <br />
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                value={profileDetails.gender} 
                                    disabled={viewOnly}
                                    onChange={(event)=>{setProfileDetails({...profileDetails, gender:event.target.value})}}
                                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group id="nationality">
                <Form.Label>Nationality</Form.Label>
                <Form.Control required type="text" placeholder="Enter your Nationality...." value={profileDetails.nationality}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, nationality:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="mb-3">
              <Form.Group id="placeBirth">
                <Form.Label>Place of Birth</Form.Label> <br />
                <Form.Control type="text" size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                value={profileDetails.placeOfBirth} placeholder='Enter your place of birth....'
                                    disabled={viewOnly}
                                    onChange={(event)=>{setProfileDetails({...profileDetails, placeOfBirth:(event.target.value).toUpperCase()})}}
                                />
              </Form.Group>
            </Col>
            <Col lg={4} className="mb-3">
              <Form.Group id="district">
                <Form.Label>District</Form.Label>
                <Form.Control required type="text" value={profileDetails.birthDistrict}
                  disabled={viewOnly} placeholder='Enter your district....'
                  onChange={(event)=>{setProfileDetails({...profileDetails, birthDistrict:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>

            <Col lg={4} className="mb-3">
              <Form.Group id="state">
                <Form.Label>State</Form.Label>
                <Form.Control required type="text" value={profileDetails.birthState}
                  disabled={viewOnly} placeholder='Enter your state....'
                  onChange={(event)=>{setProfileDetails({...profileDetails, birthState:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="mb-3">
              <Form.Group id="religion">
                <Form.Label>Religious Status</Form.Label> <br />
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                value={profileDetails.religiousStatus} 
                                 disabled={viewOnly}
                                 onChange={(event)=>{setProfileDetails({...profileDetails, religiousStatus:event.target.value})}}
                                >
                  <option value="Scholastic">Scholastic</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={4} className="mb-3">
              <Form.Group id="denomination">
                <Form.Label>Parish & Diocese of Origin</Form.Label>
                <Form.Control required type="text" value={profileDetails.parishDiocese}
                  disabled={viewOnly} placeholder='Enter your parish & diocese details....'
                  onChange={(event)=>{setProfileDetails({...profileDetails, parishDiocese:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group id="diocese">
                <Form.Label>Congregation and Province</Form.Label>
                <Form.Control required type="text" value={profileDetails.congregationProvince}
                  disabled={viewOnly} placeholder='Enter your congregation & province details....'
                  onChange={(event)=>{setProfileDetails({...profileDetails, congregationProvince:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
          </Row>
          <Card> Local Address
          <Row>
          <Col sm={12} className="mb-3">
              <Form.Group id="address">
                <Form.Label>Address</Form.Label>
                <Form.Control required type="text" value={profileDetails.localAddress}
                    disabled={viewOnly} placeholder='Enter your local address...'
                    onChange={(event)=>{setProfileDetails({...profileDetails, localAddress:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
              </Col>
          </Row>
          <Row>
          <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>State</Form.Label>
                <Form.Control required type="text" placeholder="Enter your State" value={profileDetails.localState}
                    disabled={viewOnly}
                    onChange={(event)=>{setProfileDetails({...profileDetails, localState:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id="localPincode">
                <Form.Label>Pincode</Form.Label>
                <Form.Control required type="tel" placeholder="Postal Pincode" value={profileDetails.localPincode}
                    disabled={viewOnly}
                    onChange={(event)=>{setProfileDetails({...profileDetails, localPincode:event.target.value})}}
                />
              </Form.Group>
            </Col>  
            <Col sm={4}>
              <Form.Group id="localContact">
                <Form.Label>Contact No</Form.Label>
                <Form.Control required type="tel" placeholder="phone number...." value={profileDetails.localContact}
                    disabled={viewOnly}
                    onChange={(event)=>{setProfileDetails({...profileDetails, localContact:event.target.value})}}
                />
              </Form.Group>
            </Col>            
          </Row>
          </Card>
          <Card> Permanent Address
          <Row>
          <Col sm={12} className="mb-3">
              <Form.Group id="address">
                <Form.Label>Address</Form.Label>
                <Form.Control required type="text" value={profileDetails.perAddress}
                                  disabled={viewOnly} placeholder='Enter your permanent address...'
                                  onChange={(event)=>{setProfileDetails({...profileDetails, perAddress:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
              </Col>
          </Row>
          <Row>
          <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>State</Form.Label>
                <Form.Control required type="text" placeholder="Enter your State" value={profileDetails.perState}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, perState:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id="zip">
                <Form.Label>Pincode</Form.Label>
                <Form.Control required type="tel" placeholder="Postal Pincode" value={profileDetails.perPincode}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, perPincode:event.target.value})}}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id="zip">
                <Form.Label>Contact No</Form.Label>
                <Form.Control required type="tel" placeholder="Contact no...." value={profileDetails.perContactNo}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, perContactNo:event.target.value})}}
                />
              </Form.Group>
            </Col>            
          </Row>
          </Card>
</Form>
</Card.Body>
</Card>
    </div>
  )
}
